import * as React from "react"
import { BackButton, Heading, VideoGallery } from "../../components"

const VideoPage = () => {
  return (
    <div className="container">
      <Heading title="Video" cursive="Portfolio" />
      <BackButton />
      <VideoGallery />
    </div>
  )
}

export default VideoPage

export const Head = () => <title>TMOY || Videos</title>